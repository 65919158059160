import { ElementRef, Injectable, QueryList } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  // Subject to emit scroll events
  private scrollSubject = new Subject<void>();

  // Observable that components can subscribe to for scroll events
  scrollToAlert$ = this.scrollSubject.asObservable();

  /**
   * Triggers a scroll event
   * This method can be called from components to initiate scrolling
   */
  triggerScroll() {
    this.scrollSubject.next();
  }

  /**
   * Scrolls to the first visible alert in the provided list of alert containers
   * @param alertContainers QueryList of ElementRefs representing alert containers
   */
  scrollToVisibleAlert(alertContainers: QueryList<ElementRef>) {
    setTimeout(() => {
      const visibleAlert = alertContainers.find(
        (container) => !container.nativeElement.children[0].hidden
      );
      if (visibleAlert) {
        visibleAlert.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }, 0);
  }
}
