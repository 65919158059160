import { Invoice } from '@patient-ui/shared/models';

export const MOCK_INVOICES_SMS_UNPAID: Invoice = {
  number: '12345',
  zipCode: '27215',
  balanceDue: 280,
  invoiceAmount: 300,
  billingAddress: {
    line1: '3060 S. Church St.',
    line2: '',
    city: 'Burlington',
    state: 'NC',
    zipCode: '27215',
  },
  specimenNumber: '12345',
  dateOfService: '2020-01-01',
  invoiceDate: '2020-01-02',
  dunningCycleCode: '6',
  documentKey: '',
  patientName: 'Test Patient',
  pid: '12345',
  physicianDetail: {
    firstName: 'Test',
    lastName: 'Doctor',
    middleName: '',
    prefix: 'Dr.',
    orgName: '',
    practiceLocationAddress: {
      line1: '3060 S. Church St.',
      line2: '',
      city: 'Burlington',
      state: 'NC',
      zipCode: '27215',
    },
  },
  totalCharges: 0,
  adjustments: 0,
  medicarePaid: 0,
  medicaidPaid: 0,
  insurancePaid: 0,
  patientPaid: 0,
  orderingPhyAccountNumber: '',
  patientInvoiceDetail: {
    invoiceNumber: '20056637',
    accountNumber: '32102405',
    accountNpi: '1023080454',
    taxId: '13-3757370',
    mailToBarcode: '969750501',
    participateZip: '20056',
    billCode: 'XI',
    billDate: '2022-05-24',
    billTo: {
      firstName: 'NAPP',
      lastName: 'ANITA',
      address: {
        street: '5450 MILLSTREAM RD',
        city: 'MC LEANSVILLE',
        state: 'NC',
        zip: {
          code: '27301',
        },
      },
      phone: '3366753396',
    },
    dunning: {
      number: 5,
      suffix: 'A',
      specialDunning: 'Y',
      specialCategory: 'COPAY/DEDUCTIBLE/COINSURANCE',
      message: [],
    },
    specimenNumber: '214330500140',
    specimenDate: '2022-05-23',
    patient: {
      lastName: 'ANITA',
      firstName: 'NAPP',
      dateOfBirth: '2004-03-31',
      gender: 'F',
      relationToSubscriber: '1',
    },
    physician: {
      name: 'COMMUNITY FAMILY PRACTICE',
      address: {
        street: '260 MERRIMON AVE STE 200',
        city: 'ASHEVILLE',
        state: 'NC',
        zip: {
          code: '28801',
        },
      },
    },
    lab: {
      code: 'CB',
      name: 'LA',
      brandCode: 'LCA',
      address: {
        street: '6370 WILCOX ROAD',
        city: 'DUBLIN',
        state: 'OH',
        zip: {
          code: '43016',
        },
      },
    },
    orderedTests: [
      {
        testNumber: '001818',
        testType: 'T',
      },
      {
        testNumber: '998085',
        testType: 'T',
      },
    ],
    diagnosisCodes: ['Z00.00'],
    invoiceTests: [
      {
        testName: 'Glucose, Plasma',
        price: '37.00',
      },
      {
        testName: 'Venipuncture',
        price: '25.00',
      },
    ],
    amount: {
      total: {
        payments: '0.00',
        adjustments: '0.00',
        pcDiscount: '0.00',
        medicare: {
          billed: '0.00',
          payments: '0.00',
          denials: '0.00',
          deduction: '0.00',
        },
        medicaid: {
          billed: '0.00',
          payments: '0.00',
          deduction: '0.00',
        },
        insurance: {
          payments: '0.00',
        },
      },
      balance: {
        charges: '62.00',
        adjustments: '0.00',
        insurance: '0.00',
        patient: '0.00',
        due: '62.00',
        medicareMedicaidTotal: '0.00',
      },
    },
    ocr: {
      specimenNumber: '214330500140',
      amount: '62.00',
      checkDigit: 3,
    },
    insurance: {
      payments: null,
      denials: null,
      deduction: null,
      accountName: 'CIGNA',
      subscriberNumber: null,
      types: null,
      billed: null,
    },
    remit: {
      companyName: 'Laboratory Corporation of America Holdings',
      address: {
        street: 'P.O. BOX 2240',
        city: null,
        state: null,
        zip: null,
      },
      phoneNumber: '8008456167',
      web: 'www.labcorp.com/billing',
    },
    drawLocation: 'OTHER',
  },
};

/* const mockInvoices: Invoice[] = []; */

export const mockInvoices: Invoice[] = [
  {
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '27523-6727',
    },
    totalCharges: null,
    balanceDue: 265,
    adjustments: null,
    medicarePaid: null,
    medicaidPaid: null,
    insurancePaid: null,
    patientPaid: null,
    transactionIds: ['202411080074417'],
    encryptedTransactionIds: [],
    dateOfService: '2023-02-16',
    invoiceDate: '2025-01-27',
    dunningCycleCode: '3',
    number: '01217769',
    patientFirstName: 'USER',
    patientLastName: 'FOURTY',
    patientDateOfBirth: '',
    specimenNumber: '304779911140',
    patientInvoiceDetail: {
      invoiceNumber: '',
      accountNumber: '',
      accountNpi: '',
      taxId: '',
      mailToBarcode: '',
      participateZip: '',
      billCode: '',
      billDate: '',
      billTo: undefined,
      dunning: {
        number: 3,
        suffix: 'A',
        specialDunning: 'Y',
        specialCategory: 'OUT OF POCKET',
        message: [],
      },
      specimenNumber: '',
      specimenDate: '',
      patient: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        relationToSubscriber: '',
      },
      physician: {
        name: '',
      },
      lab: undefined,
      orderedTests: [],
      diagnosisCodes: [],
      invoiceTests: [],

      amount: {
        total: {
          adjustments: '0.00',
          pcDiscount: '0.00',
          medicare: {
            billed: '0.00',
            payments: '0.00',
            denials: '0.00',
            deduction: '0.00',
          },
          medicaid: {
            billed: '0.00',
            payments: '0.00',
            deduction: '0.00',
          },
          insurance: {
            payments: '0.00',
          },
          payments: '',
        },
        balance: {
          charges: '0.00',
          adjustments: '0.00',
          insurance: '0.00',
          patient: '0.00',
          due: '0.00',
          medicareMedicaidTotal: '0.00',
        },
      },

      ocr: undefined,
      insurance: {
        payments: null,
        denials: null,
        deduction: null,
        accountName: 'CIGNA',
        subscriberNumber: null,
        types: null,
        billed: null,
      },
      remit: undefined,
      drawLocation: undefined,
    },
    isInPaymentPlan: false,
    isPastDue: true,
    invoiceAmount: 0,
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
    },
    orderingPhyAccountNumber: '',
  },
  {
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '27523-6727',
    },
    totalCharges: null,
    balanceDue: 247,
    adjustments: null,
    medicarePaid: null,
    medicaidPaid: null,
    insurancePaid: null,
    patientPaid: null,
    transactionIds: ['202411080074417'],
    encryptedTransactionIds: [],
    dateOfService: '2023-02-16',
    invoiceDate: '2025-01-27',
    dunningCycleCode: '7',
    number: '00038323',
    patientFirstName: 'USER',
    patientLastName: 'FOURTY',
    patientDateOfBirth: '',
    specimenNumber: '304779911140',
    patientInvoiceDetail: {
      invoiceNumber: '',
      accountNumber: '',
      accountNpi: '',
      taxId: '',
      mailToBarcode: '',
      participateZip: '',
      billCode: '',
      billDate: '',
      billTo: undefined,
      dunning: {
        number: 7,
        suffix: 'A',
        specialDunning: 'Y',
        specialCategory: 'OUT OF POCKET',
        message: [],
      },
      specimenNumber: '',
      specimenDate: '',
      patient: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        relationToSubscriber: '',
      },
      physician: {
        name: '',
      },
      lab: undefined,
      orderedTests: [],
      diagnosisCodes: [],
      invoiceTests: [],

      amount: {
        total: {
          adjustments: '0.00',
          pcDiscount: '0.00',
          medicare: {
            billed: '0.00',
            payments: '0.00',
            denials: '0.00',
            deduction: '0.00',
          },
          medicaid: {
            billed: '0.00',
            payments: '0.00',
            deduction: '0.00',
          },
          insurance: {
            payments: '0.00',
          },
          payments: '',
        },
        balance: {
          charges: '0.00',
          adjustments: '0.00',
          insurance: '0.00',
          patient: '0.00',
          due: '0.00',
          medicareMedicaidTotal: '0.00',
        },
      },

      ocr: undefined,
      insurance: {
        payments: null,
        denials: null,
        deduction: null,
        accountName: 'CIGNA',
        subscriberNumber: null,
        types: null,
        billed: null,
      },
      remit: undefined,
      drawLocation: undefined,
    },
    isInPaymentPlan: false,
    isPastDue: true,
    invoiceAmount: 0,
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
    },
    orderingPhyAccountNumber: '',
  },
  {
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '27523-6727',
    },
    totalCharges: null,
    balanceDue: 393.37,
    adjustments: null,
    medicarePaid: null,
    medicaidPaid: null,
    insurancePaid: null,
    patientPaid: null,
    transactionIds: ['202411080074417'],
    encryptedTransactionIds: [],
    dateOfService: '2023-02-16',
    invoiceDate: '2025-01-27',
    dunningCycleCode: '4',
    number: '01087022',
    patientFirstName: 'USER',
    patientLastName: 'FOURTY',
    patientDateOfBirth: '',
    specimenNumber: '304779911140',
    patientInvoiceDetail: {
      invoiceNumber: '',
      accountNumber: '',
      accountNpi: '',
      taxId: '',
      mailToBarcode: '',
      participateZip: '',
      billCode: '',
      billDate: '',
      billTo: undefined,
      dunning: {
        number: 4,
        suffix: 'A',
        specialDunning: 'Y',
        specialCategory: 'OUT OF POCKET',
        message: [],
      },
      specimenNumber: '',
      specimenDate: '',
      patient: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        relationToSubscriber: '',
      },
      physician: {
        name: '',
      },
      lab: undefined,
      orderedTests: [],
      diagnosisCodes: [],
      invoiceTests: [],

      amount: {
        total: {
          adjustments: '0.00',
          pcDiscount: '0.00',
          medicare: {
            billed: '0.00',
            payments: '0.00',
            denials: '0.00',
            deduction: '0.00',
          },
          medicaid: {
            billed: '0.00',
            payments: '0.00',
            deduction: '0.00',
          },
          insurance: {
            payments: '0.00',
          },
          payments: '',
        },
        balance: {
          charges: '0.00',
          adjustments: '0.00',
          insurance: '0.00',
          patient: '0.00',
          due: '0.00',
          medicareMedicaidTotal: '0.00',
        },
      },

      ocr: undefined,
      insurance: {
        payments: null,
        denials: null,
        deduction: null,
        accountName: 'CIGNA',
        subscriberNumber: null,
        types: null,
        billed: null,
      },
      remit: undefined,
      drawLocation: undefined,
    },
    isInPaymentPlan: false,
    isPastDue: true,
    invoiceAmount: 0,
    pid: '',
    physicianDetail: {
      firstName: '',
      lastName: '',
    },
    orderingPhyAccountNumber: '',
  },
];
