import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private showNavbarStripe: BehaviorSubject<boolean>;
  private showNavbarButton: BehaviorSubject<boolean>;
  private showFooterAppButtons: BehaviorSubject<boolean>;
  private showFooterContent: BehaviorSubject<boolean>;
  private showHeaderLinks: BehaviorSubject<boolean>;
  private showOndemandLinks: BehaviorSubject<boolean>;
  private showSignInButton: BehaviorSubject<boolean>;
  private showOndemandHeader: BehaviorSubject<boolean>;
  private passwordResetThroughEmail: BehaviorSubject<boolean>;
  private passwordResetThroughPhone: BehaviorSubject<string>;
  private showEmailAddressConfirmationPage: BehaviorSubject<string>;
  private showRequestPaymentDue: BehaviorSubject<string>;
  private showPortalHeaderLinks: BehaviorSubject<boolean>;
  private signOutClicked: BehaviorSubject<boolean>;
  private showSmsInsuranceLink: BehaviorSubject<boolean>;

  constructor() {
    this.showNavbarStripe = new BehaviorSubject<boolean>(true);
    this.showNavbarButton = new BehaviorSubject<boolean>(true);
    this.showFooterAppButtons = new BehaviorSubject<boolean>(true);
    this.showFooterContent = new BehaviorSubject<boolean>(true);
    this.showHeaderLinks = new BehaviorSubject<boolean>(true);
    this.showOndemandLinks = new BehaviorSubject<boolean>(false);
    this.showSignInButton = new BehaviorSubject<boolean>(true);
    this.showOndemandHeader = new BehaviorSubject<boolean>(false);
    this.passwordResetThroughEmail = new BehaviorSubject<boolean>(true);
    this.passwordResetThroughPhone = new BehaviorSubject<string>('');
    this.showEmailAddressConfirmationPage = new BehaviorSubject<string>('');
    this.showRequestPaymentDue = new BehaviorSubject<string>('');
    this.showPortalHeaderLinks = new BehaviorSubject<boolean>(false);
    this.signOutClicked = new BehaviorSubject<boolean>(false);
    this.showSmsInsuranceLink = new BehaviorSubject<boolean>(false);
  }
  getShowNavbarStripe(): Observable<boolean> {
    return this.showNavbarStripe.asObservable();
  }

  setShowNavbarStripe(showStripe: boolean): void {
    this.showNavbarStripe.next(showStripe);
  }

  getShowNavbarButton(): Observable<boolean> {
    return this.showNavbarButton.asObservable();
  }

  setShowNavbarButton(showButton: boolean): void {
    this.showNavbarButton.next(showButton);
  }

  getShowFooterAppButtons(): Observable<boolean> {
    return this.showFooterAppButtons.asObservable();
  }

  setShowFooterAppButtons(showButtons: boolean): void {
    this.showFooterAppButtons.next(showButtons);
  }

  getShowFooterContent(): Observable<boolean> {
    return this.showFooterContent.asObservable();
  }

  setShowFooterContent(showFooterContent: boolean): void {
    this.showFooterContent.next(showFooterContent);
  }

  getShowHeaderLinks(): Observable<boolean> {
    return this.showHeaderLinks.asObservable();
  }

  setShowHeaderLinks(showHeaderLinks: boolean): void {
    this.showPortalHeaderLinks.next(false);
    this.showHeaderLinks.next(showHeaderLinks);
  }

  //TODO: Remove this code when we remove password reset flow from Patient portal
  getShowOndemandLinks(): Observable<boolean> {
    return this.showOndemandLinks.asObservable();
  }

  setShowOndemandLinks(showOndemandLinks: boolean): void {
    this.showOndemandLinks.next(showOndemandLinks);
  }

  getPasswordResetThroughEmail(): Observable<boolean> {
    return this.passwordResetThroughEmail.asObservable();
  }

  setPasswordResetThroughEmail(passwordResetThroughEmail: boolean): void {
    this.passwordResetThroughEmail.next(passwordResetThroughEmail);
  }

  getSignOutClicked(): Observable<boolean> {
    return this.signOutClicked.asObservable();
  }
  setSignOutClicked(signOutClicked: boolean): void {
    this.signOutClicked.next(signOutClicked);
  }
  getpasswordResetThroughPhone(): Observable<string> {
    return this.passwordResetThroughPhone.asObservable();
  }

  setPasswordResetThroughPhone(passwordResetThroughPhone: string): void {
    this.passwordResetThroughPhone.next(passwordResetThroughPhone);
  }

  getShowOndemandHeader(): Observable<boolean> {
    return this.showOndemandHeader.asObservable();
  }

  setShowOndemandHeader(showOndemandLinks: boolean): void {
    this.showOndemandHeader.next(showOndemandLinks);
  }

  getShowSignInButton(): Observable<boolean> {
    return this.showSignInButton.asObservable();
  }

  setShowSignInButton(showSignInButton: boolean): void {
    this.showSignInButton.next(showSignInButton);
  }

  getShowEmailAddressConfirmationPage(): Observable<string> {
    return this.showEmailAddressConfirmationPage.asObservable();
  }
  setShowEmailAddressConfirmationPage(
    showEmailAddressConfirmationPage: string
  ): void {
    this.showEmailAddressConfirmationPage.next(
      showEmailAddressConfirmationPage
    );
  }
  getRequestPaymentDue(): Observable<string> {
    return this.showRequestPaymentDue.asObservable();
  }
  setRequestPaymentDue(showRequestPaymentDue: string): void {
    this.showRequestPaymentDue.next(showRequestPaymentDue);
  }
  getShowPortalHeaderLinks(): Observable<boolean> {
    return this.showPortalHeaderLinks.asObservable();
  }

  setShowPortalHeaderLinks(showPortalHeaderLinks: boolean): void {
    this.showHeaderLinks.next(false);
    this.showPortalHeaderLinks.next(showPortalHeaderLinks);
  }
  getShowSmsInsuranceLink(): Observable<boolean> {
    return this.showSmsInsuranceLink.asObservable();
  }
  public setShowSmsInsuranceLink(showSmsInsuranceLink: boolean) {
    this.showSmsInsuranceLink.next(showSmsInsuranceLink);
  }

  resetDefaults() {
    this.setShowNavbarStripe(true);
    this.setShowNavbarButton(true);
    this.setShowFooterContent(true);
    this.setShowHeaderLinks(true);
    this.setShowOndemandLinks(false);
    this.setShowOndemandHeader(false);
    this.setPasswordResetThroughEmail(true);
    this.setPasswordResetThroughPhone('');
    this.setShowEmailAddressConfirmationPage('');
    this.setRequestPaymentDue('');
    this.setShowPortalHeaderLinks(false);
    this.setSignOutClicked(false);
    this.setShowSignInButton(true);
    this.setShowSmsInsuranceLink(false);
  }
}
