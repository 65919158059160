import { Invoice } from './invoice.model';

export interface ISplunkMetric {
  metricKey: MetricKey | ResultMetricKey | PaymentPlanMetricKey;
  metricValue?: string | boolean | number | Invoice[];
}

export enum MetricKey {
  dashboardId = 'DashboardID',
  eventCode = 'EventCode',
  lpId = 'LPID',
  invoiceNumber = 'InvoiceNumber',
  specimenNumber = 'SpecimenNumber',
  templateId = 'TemplateId',
  mobileNumber = 'MobileNumber',
  userType = 'UserType',
  alertMsg = 'AlertMessage',
  reasonCode = 'ReasonCode',
  sessionID = 'SessionID',
  showErrorFlag = 'ShowErrorFlag',
  patientId = 'PatientId',
  balanceDue = 'BalanceDue',
  dunningCycle = 'DunningCycle',
}

export enum EventCode {
  landed = 'LANDED',
  paymentDetail = 'PAYMENT_DETAIL',
  paymentReview = 'PAYMENT_REVIEW',
  paymentConfirmation = 'PAYMENT_CONFIRMATION',
  textOptInLanded = 'TEXT_OPT_IN_LANDED',
  updateInfo = 'UPDATE_INFO',
  registrationLanded = 'REGISTRATION_LANDED',
  registrationSubmit = 'REGISTRATION_SUBMIT',
  registrationComplete = 'REGISTRATION_COMPLETE',
  loginLanded = 'LOGIN_LANDED',
  mfaReview = 'MFA_REVIEW',
  mfaSmsEnrollment = 'MFA_SMS_ENROLLMENT',
  mfaRemoveFactor = 'MFA_REMOVE_FACTOR',
  mfaOktaEnrollment = 'MFA_OKTA_ENROLLMENT',
  mfaGoogleEnrollment = 'MFA_GOOGLE_ENROLLMENT',
  mfaPasswordValidation = 'MFA_PASSWORD_VALIDATION',
  mfaDecline = 'MFA_DECLINE',
  mfaLater = 'MFA_DO_IT_LATER',
  mfaTurnOn = 'MFA_TURN_ON',
  mfaUserPreference = 'MFA_LOADING_USER_FACTOR',
  mfaDashBoard = 'MFA_PORTAL_DASHBOARD_COMPONENT',
  mfaOpenModal = 'MFA_OPEN_MODAL',
  mfaDoneModal = 'MFA_DONE_MODAL',
  mfaOktaCancel = 'MFA_OKTA_CANCEL',
  mfaSetup = 'MFA_SETUP',
  mfaGoogleCancel = 'MFA_GOOGLE_CANCEL',
  mfaSmsFactor = 'MFA_SMS_FACTOR',
  mfaOktaFactor = 'MFA_OKTA_FACTOR',
  mfaGoogleFactor = 'MFA_GOOGLE_FACTOR',
  updateInsurance = 'RU_UPDATE_INSURANCE',
  paymentPlanReview = 'PAYMENT_PLAN_REVIEW',
  paymentPlanCreateStart = 'PAYMENT_PLAN_CREATE_START',
  paymentMethod = 'PAYMENT_PLAN_METHOD',
  paymentPlanTerms = 'PAYMENT_PLAN_TERMS',
  paymentPlanLogin = 'PAYMENT_PLAN_LOGIN',
  PaymentPlanConfirmation = 'PAYMENT_PLAN_CONFIRMATION',
  SMSInvoiceValidation = 'SMSInvoiceValidation',
}

export interface IBadDebtMetric {
  eventCode: string;
  templateId: string;
  lpId?: string;
  invoiceNumber?: string;
  specimenNumber?: string;
  mobileNumber?: string;
  userType: string;
  dashboardId?: string;
  alertMsg?: string;
  reasonCode?: string;
  sessionID?: string;
  showErrorFlag?: boolean;
  patientId?: string;
  balanceDue?: string;
  dunningCycle?: string;
}

export interface IMfaMetric {
  eventCode: string;
  mobileNumber?: string;
  templateId: string;
  userType: string;
  dashboardId?: string;
  alertMsg?: string;
  reasonCode?: string;
  sessionID?: string;
}

export interface IPaymentPlanMetric {
  dashboardId?: string;
  eventCode: string;
  invoiceNumber: string;
  templateId: string;
  userType: string;
  allInvoices?: Invoice[];
  reasonCode?: string;
  alertMsg?: string;
  sessionID?: string;
  showErrorFlag?: boolean;
}

export enum PaymentPlanMetricKey {
  dashboardId = 'DashboardID',
  eventCode = 'EventCode',
  invoiceNumber = 'InvoiceNumber',
  allInvoices = 'AllInvoices',
  templateId = 'TemplateId',
  userType = 'UserType',
  reasonCode = 'ReasonCode',
  alertMsg = 'AlertMsg',
  sessionID = 'SessionID',
  showErrorFlag = 'ShowErrorFlag',
}
export interface IResultMetric {
  dashboardId: string;
  eventCode: string;
  userType: string;
  userId?: string;
  patientMasterId?: number;
  statusCode?: number;
  alertMsg?: string;
  resultId?: string;
}

export enum ResultMetricKey {
  dashboardId = 'DashboardID',
  eventCode = 'EventCode',
  userType = 'UserType',
  userId = 'UserID',
  patientMasterId = 'PatientMasterID',
  statusCode = 'StatusCode',
  alertMsg = 'AlertMsg',
  resultId = 'ResultID',
}
