import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-dropdown-fields',
  templateUrl: './dropdown-fields.component.html',
  styleUrls: ['./dropdown-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFieldsComponent {
  @Input() iconOpen = 'more_vert';
  @Input() iconClose = 'close';
  @Input() btnClass = 'btn btn-light px-2';
  @Input() divClass = '';
  @Input() autoClose: true | false | 'outside' | 'inside' = false;
  /** The placement of the drop-down menu, sent directly to ngbDropdown */
  @Input() placement = 'bottom-left bottom-right top-left top-right';
  /** (Optional) The breakpoint at which to forcefully render the drop-down
   * menu in-line */
  @Input() expandSize: '' | 'sm' | 'md' | 'lg' | 'xl' = '';
  @Input() btnText = '';

  handleDropdownNavigation(event: KeyboardEvent): void {
    const dropdown = event.currentTarget as HTMLElement;
    const focusableItems = dropdown.querySelectorAll(
      '.dropdown-item[tabindex="0"]'
    );
    const focusableArray = Array.from(focusableItems) as HTMLElement[];

    if (focusableArray.length === 0) return;

    const currentIndex = focusableArray.indexOf(
      document.activeElement as HTMLElement
    );

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const nextIndex = (currentIndex + 1) % focusableArray.length;
      focusableArray[nextIndex].focus();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const prevIndex =
        (currentIndex - 1 + focusableArray.length) % focusableArray.length;
      focusableArray[prevIndex].focus();
    }
  }
}
