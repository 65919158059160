import { createAction, props } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';
import {
  BraintreeTransactionSetupOutput,
  DownloadInvoiceRequest,
  IFlagValueResponse,
  IInsuranceUpdate,
  IInvoiceLookup,
  IInvoiceSearch,
  IRequestError,
  IValidationErrors,
  InsuranceProvider,
  Invoice,
  InvoiceSummary,
  Payment,
  PaymentAuthorizationRequest,
  PaymentAuthorizationResponse,
  PaymentInvoice,
  PaymentPlanAuthorizationRequest,
  PaymentPlanAuthorizationResponse,
  PaymentPlanOptions,
  PaymentPlanRequest,
  PaymentPlanResponse,
  PaymentReceiptResponse,
  PortalUser,
  ProcessingInvoice,
  QueryAccessionedOrderRequest,
  QueryAccessionedOrderResponse,
  TokenDetails,
  UpdateInsuranceFailedResponse,
} from '@patient-ui/shared/models';

export const addInvoice = createAction(
  `[Invoice] Add Invoice`,
  props<{ payload: Invoice }>()
);

export const addPayment = createAction(
  `[Invoice] Add Payment`,
  props<{ payload: PaymentInvoice }>()
);

export const addSelectedPaymentList = createAction(
  `[Invoice] Add Selected Payment List`,
  props<{ payload: Invoice[] }>()
);

export const clearAllSelectedPaymentsAndInvoices = createAction(
  `[Invoice] Clear All Selected Payments and Invoices`
);

export const clearPaymentAuthorizationRequest = createAction(
  `[Invoice] Clear Payment Authorization Request`
);

export const clearInvoicePdf = createAction(`[Invoice] Clear Invoice PDF`);

export const clearFindInvoiceStatus = createAction(
  `[Invoice] Clear Find Invoice Status`
);

export const clearInvoiceSearchResult = createAction(
  `[Invoice] Clear Invoice Search Result`
);

export const clearPaymentDeclined = createAction(
  `[Invoice] ClearPaymentDeclined`
);

export const clearPaymentProcessOnConfirmation = createAction(
  `[Invoice] Clear Payment Process State`
);

export const clearTokenData = createAction(`[Invoice] Clear Token Data`);

export const deletePayment = createAction(
  `[Invoice] Delete Payment`,
  props<{ payload: PaymentInvoice }>()
);

export const duplicateInvoice = createAction(
  `[Invoice] Duplicate Invoice`,
  props<{ payload: boolean }>()
);

export const findInvoice = createAction(
  `[Invoice] Find Invoice`,
  props<{ payload: IInvoiceSearch; campaign?: string }>()
);

export const findInvoiceFailure = createAction(
  `[Invoice] Find Invoice Failure`,
  props<{ payload: RequestStatus }>()
);

export const findInvoiceSuccess = createAction(
  `[Invoice] Find Invoice Success`,
  props<{ payload: Invoice }>()
);

export const generateReceipt = createAction(
  `[Invoice] Generate Receipt`,
  props<{ confirmationNumber: string }>()
);

export const generateReceiptFailure = createAction(
  `[Invoice] Generate Receipt Failure`,
  props<{ response: PaymentReceiptResponse }>()
);

export const generateReceiptReset = createAction(
  `[Invoice] Generate Receipt Reset`
);

export const generateReceiptSuccess = createAction(
  `[Invoice] Generate Receipt Success`,
  props<{ response: PaymentReceiptResponse }>()
);

export const getInvoice = createAction(
  `[Invoice] Get Invoice`,
  props<{ payload: IInvoiceSearch; campaign?: string }>()
);

export const getInvoiceFailure = createAction(
  `[Invoice] Get Invoice Failure`,
  props<{ payload: RequestStatus }>()
);

export const getInvoiceSuccess = createAction(
  `[Invoice] Get Invoice Success`,
  props<{ payload: Invoice }>()
);

export const getInvoicePdf = createAction(
  `[Invoice] Get Invoice PDF`,
  props<{ payload: DownloadInvoiceRequest }>()
);

export const getInvoicePdfFailure = createAction(
  `[Invoice] Get Invoice PDF Failure`
);

export const getInvoicePdfSuccess = createAction(
  `[Invoice] Get Invoice PDF Success`,
  props<{ payload: Blob }>()
);

export const getInvoiceSummary = createAction(
  `[Invoice] Get Invoice Summary`,
  props<{ payload: PortalUser }>()
);

export const getInvoiceSummaryFailure = createAction(
  `[Invoice] Get Invoice Summary Failure`
);

export const getInvoiceSummarySuccess = createAction(
  `[Invoice] Get Invoice Summary Success`,
  props<{ payload: InvoiceSummary }>()
);

export const createPaymentPlan = createAction(
  `[Invoice] Create Payment Plan`,
  props<{ payload: PaymentPlanRequest }>()
);

export const createPaymentPlanFailure = createAction(
  `[Invoice] Create Payment Plan Failure`
);

export const createPaymentPlanSuccess = createAction(
  `[Invoice] Create Payment Plan Success`,
  props<{ response: PaymentPlanResponse }>()
);

export const addSelectedPaymentPlanOption = createAction(
  `[Invoice] Add Selected Payment Plan Option`,
  props<{ payload: PaymentPlanOptions }>()
);

export const clearSelectedPaymentPlanOption = createAction(
  `[Invoice] Clear Selected Payment Plan Option`
);

export const clearPatientInvoice = createAction(
  `[Invoice] Clear Patient Invoice`
);

export const getPatientInvoice = createAction(
  `[Invoice] Get Patient Invoice`,
  props<{ payload: IInvoiceLookup }>()
);

export const getPatientInvoiceFailure = createAction(
  `[Invoice] Get Patient Invoice Failure`
);

export const getPatientInvoiceSuccess = createAction(
  `[Invoice] Get Patient Invoice Success`,
  props<{ payload: Invoice }>()
);

export const getPaymentPlanAuthorization = createAction(
  `[Invoice] Get Payment Plan Authorization`,
  props<{ payload: PaymentPlanAuthorizationRequest; campaign: string }>()
);

export const getPaymentPlanAuthorizationFailure = createAction(
  `[Invoice] Get Payment Plan Authorization Failure`,
  props<{ errors: IValidationErrors | undefined }>()
);

export const getPaymentPlanAuthorizationReset = createAction(
  `[Invoice] Get Payment Plan Authorization Reset`
);

export const getPaymentPlanAuthorizationSuccess = createAction(
  `[Invoice] Get Payment Plan  Authorization Success`,
  props<{ payload: PaymentPlanAuthorizationResponse }>()
);

export const getPaymentAuthorization = createAction(
  `[Invoice] Get Payment Authorization`,
  props<{ payload: PaymentAuthorizationRequest; campaign: string }>()
);

export const getPaymentAuthorizationFailure = createAction(
  `[Invoice] Get Payment Authorization Failure`,
  props<{ errors: IValidationErrors | undefined }>()
);

export const getPaymentAuthorizationReset = createAction(
  `[Invoice] Get Payment Authorization Reset`
);

export const getPaymentAuthorizationSuccess = createAction(
  `[Invoice] Get Payment Authorization Success`,
  props<{ payload: PaymentAuthorizationResponse }>()
);

export const getPaymentAuthorizationTimeout = createAction(
  `[Invoice] Get Payment Authorization Timeout`,
  props<{ payload: boolean }>()
);

export const getPaymentAuthorizationReceipt = createAction(
  `[Invoice] Get Payment Authorization Receipt`,
  props<{ payload: PaymentAuthorizationResponse }>()
);

export const getPaymentAuthorizationReceiptFailure = createAction(
  `[Invoice] Get Payment Authorization Receipt Failure`
);

export const getPaymentAuthorizationReceiptReset = createAction(
  `[Invoice] Get Payment Authorization Receipt Reset`
);

export const getPaymentAuthorizationReceiptSuccess = createAction(
  `[Invoice] Get Payment Authorization Receipt Success`
);

export const getProcessingInvoices = createAction(
  `[Invoice] Get Invoices Being Processed`
);

export const getProcessingInvoicesFailure = createAction(
  `[Invoice] Get Invoices Being Processed Failure`
);

export const getProcessingInvoicesSuccess = createAction(
  `[Invoice] Get Invoices Being Processed Success`,
  props<{ payload: ProcessingInvoice[] }>()
);

export const getProviderList = createAction(`[Invoice] Get Provider List`);

export const getProviderListFailure = createAction(
  `[Invoice] Get Provider List Failure`
);

export const getProviderListReset = createAction(
  `[Invoice] Get Provider List Reset`
);

export const getProviderListSuccess = createAction(
  `[Invoice] Get Provider List Success`,
  props<{ payload: InsuranceProvider[] }>()
);

export const populateInvoicePaymentsAll = createAction(
  `[Invoice] Populate Invoice Payments for All Patients`,
  props<{ payload: Payment[] }>()
);

export const populateInvoicePaymentsFiltered = createAction(
  `[Invoice] Populate Invoice Payments Filtered by Patients`,
  props<{ payload: Payment[] }>()
);

export const removeInvoice = createAction(
  `[Invoice] Remove Invoice`,
  props<{ payload: Invoice }>()
);

export const resetState = createAction(`[Invoice] Reset State`);

export const savePaymentData = createAction(
  `[Invoice] Save Payment Data`,
  props<{ payload: PaymentAuthorizationRequest }>()
);

export const savePaymentPlanData = createAction(
  `[Invoice] Save Payment Data`,
  props<{ payload: PaymentPlanAuthorizationRequest }>()
);

export const saveTokenData = createAction(
  `[Invoice] Save Token Data`,
  props<{ payload: TokenDetails }>()
);

export const setNewPaymentMethod = createAction(
  '[Invoice] Set New Payment Method',
  props<{ isNewPaymentMethod: boolean | null | undefined }>()
);

export const updateInsurance = createAction(
  `[Invoice] Update Insurance`,
  props<{ payload: IInsuranceUpdate; campaign: string }>()
);

export const updateInsuranceFailure = createAction(
  `[Invoice] Update Insurance Failure`
);

export const updateInsuranceEVFailure = createAction(
  `[Invoice] Update Insurance Eligibility Verification Failure`,
  props<{ payload: UpdateInsuranceFailedResponse }>()
);

export const updateInsuranceReset = createAction(
  `[Invoice] Update Insurance Reset State`
);

export const updateInsuranceSuccess = createAction(
  `[Invoice] Update Insurance Success`
);

export const saveBraintreeTransactionOutput = createAction(
  `[Invoice] Save Braintree Transaction Output`,
  props<{ payload: BraintreeTransactionSetupOutput }>()
);

export const resetBraintreeTransactionOutput = createAction(
  '[Invoice] Reset Braintree Transaction Output'
);

export const queryForAccessionedOrder = createAction(
  '[Invoice] Query for Accessioned Order',
  props<{ queryRequest: QueryAccessionedOrderRequest }>()
);

export const queryForAccessionedOrderSuccess = createAction(
  '[Invoice] Query for Accessioned Order Success',
  props<{ response: QueryAccessionedOrderResponse }>()
);

export const queryForAccessionedOrderFailure = createAction(
  '[Invoice] Query for Accessioned Order Failure',
  props<{ error: IRequestError }>()
);

export const getApplePaySupportFlag = createAction(
  '[Invoice] Get Apple Pay Support Flag'
);

export const getApplePaySupportFlagSuccess = createAction(
  '[Invoice] Get Apple Pay Support Flag Success',
  props<{ response: IFlagValueResponse }>()
);

export const getApplePaySupportFlagFailure = createAction(
  '[Invoice] Get Apple Pay Support Flag Failure',
  props<{ error: IRequestError }>()
);

export const resetApplePaySupportFlag = createAction(
  '[Invoice] Reset Apple Pay Support Flag'
);

export const addInvoiceList = createAction(
  `[Invoice] Add Selected Invoice  Data`,
  props<{ payload: string[] }>()
);

export const clearInvoiceIdList = createAction(
  `[Invoice] Clear Selected Invoice  Data`
);
export const addEmail2TextPaymentPlanLink = createAction(
  `[Invoice] Add Email 2 Text Payment Plan Link`,
  props<{ payload: boolean }>()
);

export const clearEmail2TextPaymentPlanLink = createAction(
  `[Invoice] Clear Email 2 Text Payment Plan Link`
);

export const saveOriginalInvoice = createAction(
  `[Invoice] Save Original Invoice`,
  props<{ payload: Invoice }>()
);
export const addBackToBillingFlag = createAction(
  `[Invoice] Add Back To Billing Flag`,
  props<{ payload: boolean }>()
);
