<ng-container *ngIf="!showPushNotificationScreen">
  <div class="modal-body">
    <div class="alert alert-danger" role="alert" *ngIf="mfaTechDifficultiesStatus">
      <span class="alert-icon material-icons-round"><i class="alert-icon material-icons-round">warning</i></span>
      <div class="text text-charcoal">We can't complete your request at this time. Please try again later.</div>
    </div>
    <p class="mb-0">Get a push notification on your device.</p>
  </div>
  <div class="modal-footer justify-content-right">
    <button id="btnBack" type="button" class="btn btn-secondary" [disabled]="isLoading" (click)="showFactorList.emit()">
      Back
    </button>
    <button id="btnSendPush" type="button" class="btn btn-primary" [disabled]="isLoading" (click)="getCode()">
      Send Push
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showPushNotificationScreen">
  <div class="modal-body">
    <p class="mb-0">A push notification was sent to your device.</p>
  </div>
  <div class="modal-footer justify-content-right">
    <button id="btnBack" type="button" class="btn btn-secondary" [disabled]="isLoading" (click)="showInitialScreen()">
      Back
    </button>
  </div>
</ng-container>
