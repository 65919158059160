export enum InsuranceSpecialCategory {
  OUTOFPOCKET = 'OUT OF POCKET',
  OTHERINSURANCE = 'OTHER INSURANCE',
  CDC_CATEGORY = 'COPAY/DEDUCTIBLE/COINSURANCE',
  NOT_COVERED = 'NOT COVERED',
  PATIENT_RESPONSIBILITY = 'PATIENT RESPONSIBILITY',
  DMEOGRAPHIC_DENIAL = 'DEMOGRAPHIC DENIAL',
  ADDITIONAL_INFORMATION = 'ADDITIONAL INFORMATION',
  NO_RESPONSE = 'NO RESPONSE',
}
