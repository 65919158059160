import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LayoutService } from '@patient-ui/patient-web/feature-shell';
import {
  AccountRegistrationService,
  LoaderService,
  MetricState,
  PatientState,
  selectCampaignTemplateId,
  selectPatientState,
} from '@patient-ui/patient-web/store';
import { regexPatterns } from '@patient-ui/shared/constants';
import { IToken, Patient } from '@patient-ui/shared/models';

import { MfaSetupConfirmationComponent } from '../../../containers/mfa-setup-confirmation/mfa-setup-confirmation.component';

@Component({
  selector: 'patient-ui-registration-email-verify',
  templateUrl: './registration-email-verify.component.html',
  styleUrls: ['./registration-email-verify.component.scss'],
})
export class RegistrationEmailVerifyComponent implements OnInit, OnDestroy {
  @Output() emailVerified: EventEmitter<boolean> = new EventEmitter();
  @Output() signOutSelected: EventEmitter<void> = new EventEmitter();

  verifyCodeForm!: UntypedFormGroup;
  loggedIn = false;
  patient: Patient | undefined;
  destroyed = new Subject();
  proccessing$: Subject<boolean> = this.loaderService.isLoading;
  registeredEmail = '';
  token!: string;
  acttoken!: string;
  alertStatus!: 'primary' | 'success' | 'danger' | 'warning' | 'info';
  alertText!: string;
  codeResent = false;
  infoText = '';
  errorText =
    '<strong>You have entered a Verification Code that is not valid.</strong> Click <strong>Resend Code</strong> to have another code sent to you.';
  serviceErrText =
    '<strong>We cannot process your request at this time.</strong>';
  campaignId = '';

  readonly validationMessages = {
    pin: {
      required: 'This information is required',
      pattern: 'Must contain at least 4 numeric characters',
    },
  };
  emailKey = '';
  emailValue = '';
  tokenKey = '';
  tokenValue = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private accountRegistrationService: AccountRegistrationService,
    private changeDetectorRef: ChangeDetectorRef,
    private patientStore: Store<PatientState>,
    private metricStore: Store<MetricState>,
    private layoutService: LayoutService,
    private modalService: NgbModal,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.layoutService.setShowSignInButton(false);
    const currentUrl = window.location.href;
    this.sanitizeUrl(currentUrl);

    this.layoutService.setShowHeaderLinks(false);
    this.layoutService.setShowFooterAppButtons(true);
    this.token = this.route.snapshot.queryParamMap.get('token') || '';
    this.acttoken = this.route.snapshot.queryParamMap.get('acttoken') || '';
    this.registeredEmail = this.route.snapshot.queryParamMap.get('email') || '';

    this.metricStore
      .select(selectCampaignTemplateId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((campaign) => (this.campaignId = campaign || ''));

    // Top text for registration cases
    this.infoText =
      "<p><strong>IMPORTANT: </strong> Do not close this window or click the <strong>Back</strong> button on your browser.</p><p>We have sent you a one-time Verification Code. Open a new window and check your email. If you don't get an email from us at <strong>" +
      this.registeredEmail +
      '</strong> within a few minutes, check your spam folder or click <strong>Resend Code</strong>. Your code will be valid for 20 minutes.</p>';

    if (this.acttoken !== '') {
      // duplicate registration case
      this.infoText +=
        '<p>If you do not complete this verification now, your account will not be created and <strong>' +
        this.registeredEmail +
        '</strong> cannot be used as a user ID.</p>';
      this.alertStatus = 'warning';
    } else if (this.token !== '') {
      // standard registration case
      this.alertStatus = 'info';
    } else {
      // this is a logged in user that hasn't been verified yet
      this.loggedIn = true;
      this.alertStatus = 'info';

      this.patientStore
        .select(selectPatientState)
        .pipe(takeUntil(this.destroyed))
        .subscribe((patientState) => {
          this.patient = patientState.primaryAccountHolder;

          if (this.patient) {
            this.registeredEmail = this.patient.primaryEmailAddress.address;
          }
        });

      // top text for logged in case
      this.infoText =
        '<p>We have sent you a one-time Verification Code at <strong>' +
        this.registeredEmail +
        "</strong>. Enter it below to verify your account.</p> Don't have a code? Check your spam folder or click <strong>Resend Code</strong>.";
    }

    this.alertText = this.infoText;

    this.initForm();
  }

  initForm() {
    this.verifyCodeForm = new UntypedFormGroup({
      verificationCode: new UntypedFormControl('', {
        validators: [
          Validators.required,
          Validators.pattern(regexPatterns.pin),
        ],
        updateOn: 'blur',
      }),
    });
  }

  ngOnDestroy(): void {
    this.layoutService.resetDefaults();
    this.destroyed.next();
    this.destroyed.complete();
  }

  onSubmit(): void {
    this.codeResent = false;

    if (this.verifyCodeForm.invalid) {
      this.verifyCodeForm.controls.verificationCode.markAsDirty();
      return;
    }

    const verifyCode = this.verifyCodeForm.controls.verificationCode
      .value as string;

    // Basic registration path
    if (this.token !== '') {
      this.accountRegistrationService
        .patientVerifyEmail(verifyCode, this.token, this.campaignId)
        .subscribe(
          (response) => {
            //Next callback
            console.log(response);
            this.router.navigate(['account/registration/success']);
          },
          (error) => {
            //Error callback
            console.log(error);
            this.alertStatus = 'danger';
            this.alertText = this.errorText;
            this.changeDetectorRef.detectChanges();
            window.scrollTo(0, 0);
          }
        );
      // Duplicate account - create new account with different email - path
    } else if (this.acttoken !== '') {
      this.accountRegistrationService
        .activateAccount(verifyCode, this.acttoken)
        .subscribe(
          (response) => {
            const params: Record<string, string> = {};

            //Next callback
            if (response.isQuizRequired) {
              params.token = response.token;
              this.router.navigate(['account/identity-verification'], {
                queryParams: params,
              });
            } else {
              this.router.navigate(['account/registration/success'], {
                queryParams: params,
              });
            }
          },
          (error) => {
            //Error callback
            console.log(error);
            if (error.status >= 400 && error.status < 500) {
              this.alertStatus = 'danger';
              this.alertText = this.errorText;
              window.scrollTo(0, 0);
              this.changeDetectorRef.detectChanges();
            } else {
              this.router.navigate(['account/registration/unable-to-register']);
            }
          }
        );
      // Loged in user but not verified yet path
    } else if (this.loggedIn) {
      this.accountRegistrationService
        .currentPatientVerifyEmail(verifyCode, this.campaignId)
        .subscribe(
          (response) => {
            //Next callback
            console.log(response);
            // TODO: What else to do here??
            this.emailVerified.emit(true);
            this.modalService.open(MfaSetupConfirmationComponent, {
              backdrop: 'static',
              keyboard: false,
            });
          },
          (error) => {
            //Error callback
            console.log(error);
            this.alertStatus = 'danger';
            if (error.status >= 400 && error.status < 500) {
              // TODO: legacy code only checks for 400 and seems to accept other error codes?
              this.alertText = this.errorText;
              this.changeDetectorRef.detectChanges();
              window.scrollTo(0, 0);
            } else {
              this.alertText = this.serviceErrText;
              this.changeDetectorRef.detectChanges();
              window.scrollTo(0, 0);
            }
          }
        );
    }
  }

  resendCode(): void {
    this.alertText = this.infoText;
    this.alertStatus = this.acttoken !== '' ? 'warning' : 'info';
    this.verifyCodeForm.controls['verificationCode'].reset();

    if (this.loggedIn) {
      this.accountRegistrationService.currentPatientResendCode().subscribe(
        (response) => {
          //Next callback
          this.codeResent = true;
          console.log(response);
        },
        (error) => {
          //Error callback
          console.log(error);
        }
      );
    } else {
      this.accountRegistrationService
        .accountResendCode({
          mobilePhoneNumber: '',
          token: this.token !== '' ? this.token : this.acttoken,
        } as IToken)
        .subscribe(
          (response) => {
            //Next callback
            this.codeResent = true;
            console.log(
              response.token ? 'Resent code.' : "Something isn't right."
            );
          },
          (error) => {
            //Error callback
            console.log(error);
          }
        );
    }
  }

  signOut($event: Event): void {
    $event.preventDefault();
    this.signOutSelected.emit();
  }
  sanitizeUrl(url: string) {
    // Remove leading/trailing whitespace
    let sanitizedUrl = url.trim();

    // Ensure the URL starts with a valid protocol
    if (
      !sanitizedUrl.startsWith('http://') &&
      !sanitizedUrl.startsWith('https://')
    ) {
      sanitizedUrl = 'https://' + sanitizedUrl;
    }

    // Parse the URL
    const parsedUrl = new URL(sanitizedUrl);

    const queryParam = new URLSearchParams(parsedUrl.search);

    // Sanitize the hostname
    parsedUrl.hostname = parsedUrl.hostname.toLowerCase();
    const origin = parsedUrl.pathname + `?`;

    // Sanitize the path
    parsedUrl.pathname = parsedUrl.pathname.replace(/[^a-zA-Z0-9-_/.]/g, '');

    queryParam.forEach((value, key) => {
      if (key.valueOf() === 'email') {
        this.emailKey = key.replace(regexPatterns.santizeKeyValue, '');
        this.emailValue = value.replace(regexPatterns.santizeEmail, '');

        parsedUrl.searchParams.delete(key);
        parsedUrl.searchParams.append(this.emailKey, this.emailValue);
      } else {
        this.tokenKey = key.replace(regexPatterns.santizeKeyValue, '');
        this.tokenValue = value.replace(regexPatterns.santizeKeyValue, '');

        parsedUrl.searchParams.delete(key);
        parsedUrl.searchParams.append(this.tokenKey, this.tokenValue);
      }
    });

    const urlConstructed = `${origin}${this.tokenKey}=${this.tokenValue}&${this.emailKey}=${this.emailValue}`;
    // Return the sanitized URL as a string
    this.location.replaceState(urlConstructed);
  }
}
