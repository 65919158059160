import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ILinkURLText } from '@patient-ui/shared/models';

@Component({
  selector: 'ui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() addShadow = false;
  @Input() showHeader = false;
  @Input() cardId = '';
  @Input() showHeaderMenu = false;
  @Input() headerLinks!: ILinkURLText[];
  @Input() headerText!: string;
  @Input() titleText!: string;
  @Input() subTitleText!: string;
  @Input() footerLinks!: ILinkURLText[];
  @Input() addClass = '';

  showTitle!: boolean;
  showSubTitle!: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.showTitle = this.titleText && this.titleText.length > 0 ? true : false;
    this.showSubTitle =
      this.subTitleText && this.subTitleText.length > 0 ? true : false;
  }

  navigate(goToUrl: string): void {
    this.router.navigate([goToUrl]);
  }
}
