export const FORM_ERROR_MESSAGES = {
  common: {
    required: 'This is a required field.',
    invalid: 'This value is not valid.',
    minLength: 'Must be at least 2 characters.',
    alphaNumeric: 'Must be an alpha numeric character.',
    alphaNumSpecial: `Must be alphanumeric and can include one or more of the following special characters (# : , ; . ').`,
    invalidText:
      'Must be an alpha numeric character, and not contain special characters.',
  },
  insurance: {
    required: {
      insuranceName: 'Insurance Name is a required field',
      subscriberNumber: 'Subscriber ID/Member ID is a required field',
      medicaidState: 'Medicaid State is a required field',
      address: {
        line1: 'Street Address is a required field',
        line2: 'Apartment, Suite, Floor is a required field',
        city: 'City is a required field',
        state: 'State is a required field',
        zipcode: 'Zipcode is a required field',
      },
      contactEmail: 'Email is a required field',
    },
  },
  address: {
    line1: {
      minLength:
        'Street / PO Box address must contain between 3 and 50 characters.',
      maxLength:
        'Street / PO Box address must contain between 3 and 50 characters.',
      maxLength50:
        'Street / PO Box address must contain at most 50 characters.',
    },
    line2: {
      invalid: `The address can be alphanumeric and include one or more of the following special characters (# : , ; . ').`,
    },
    city: {
      minLength: 'City name must be between 3 and 50 characters.',
      maxLength: 'City name must be between 3 and 50 characters.',
      pattern:
        'Must start and end with an alpha character. Can only contain alpha characters or hyphen.',
    },
    zipCode: {
      minLength: 'Must be at least 5 numeric characters.',
      pattern: 'Must be a valid 5-digit zip code.',
    },
  },
  name: {
    invalid: 'Must be an alpha character, apostrophe, or hyphen.',

    first: {
      minLength: 'Must be at least 1 characters.',
      maxLength: 'Must be at most 25 characters.',
    },
    last: {
      minLength: 'Must be at least 2 characters.',
      maxLength: 'Must be at most 30 characters.',
    },
    middle: {
      invalid: 'Must be an alpha character.',
      maxLength: 'Must be at most 25 characters.',
    },
    suffix: {
      invalid: 'Must be an alpha character or period.',
    },
    fullNameRequired: 'Must enter at least a first and last name',
  },
  signature: {
    minLength: 'Must be at least 5 characters.',
    maxLength: 'Must be at most 82 characters.',
  },
  email: {
    pattern: 'Must be a valid email address containing at least 7 characters.',
    invalid: 'Invalid email address.',
    notEquivalent: 'Your email addresses do not match. Please try again.',
  },
  insuredEmployer: {
    invalid:
      'Inusred Employer can be alphanumeric and include one or more of the following special characters ( & : , - ).',
  },
  insuranceName: {
    invalid:
      'Insurance Name must be an alpha character and can include a space.',
  },
  insuranceGroupNumber: {
    pattern: 'Must be alphanumeric and can include a dash.',
  },
  insuranceCountry: {
    minLength: 'Minimum value must be at least 3 characters.',
  },
  verificationMethodValueMessages: {
    required: 'This information is required.',
    pattern: 'Must contain at least 4 numeric characters and must not be 0000.',
    minlength: 'Must contain at least 4 characters.',
  },
  phone: {
    pattern: 'Must be a valid phone number containing at least 10 characters.',
    invalidMobile: 'Invalid mobile number.',
  },
  additionalInvoices: {
    pattern:
      'Invoice Numbers must contain only numeric characters, spaces and commas.',
    maxLength: 'Must contain at most 98 characters.',
  },
  password: {
    pattern: `<p><strong>Your password must:</strong></p><ul><li>Be 8 to 20 characters long</li><li>Contain an upper case letter</li><li>Contain a lower case letter</li><li>Contain a number</li><li>Contain an accepted character <strong class="nowrap">! @ # $ % ^ & *</strong></ul>`,
    required: 'This field is required.',
    notEquivalent: 'Your passwords do not match. Please try again.',
    notEqualSimple: 'Passwords do not match.',
  },
  date: {
    invalidDate: 'Please enter a valid date.',
    invalidDateNotMinor:
      'Note that patients must be under 18 to be managed as a minor.',
    invalidDateNoMinors: `Please enter a valid date. Note that patients under 18 must be added to an adult's account as a dependent.`,
    pattern: 'Format mm/dd/yyyy.',
    mask: 'Format mm/dd/yyyy.',
    invalidYear: 'Please enter a 4 digit year.',
    notEquivalent: 'Your dates of birth do not match. Please try again.',
  },
};

export const resetPasswordValidationMessages = {
  emailAddress: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.email.pattern,
  },
};

export const insuranceFormValidationMessages = {
  patient: {
    firstName: {
      pattern: FORM_ERROR_MESSAGES.name.invalid,
      minlength: FORM_ERROR_MESSAGES.name.first.minLength,
    },
    middleName: {
      pattern: FORM_ERROR_MESSAGES.name.middle.invalid,
    },
    lastName: {
      pattern: FORM_ERROR_MESSAGES.name.invalid,
      minlength: FORM_ERROR_MESSAGES.name.last.minLength,
    },
    dateOfBirth: {
      pattern: FORM_ERROR_MESSAGES.date.pattern,
      invalidDate: FORM_ERROR_MESSAGES.date.invalidDate,
      mask: FORM_ERROR_MESSAGES.date.mask,
    },
    phoneNumber: {
      pattern: FORM_ERROR_MESSAGES.phone.pattern,
    },
    emailAddress: {
      minlength: FORM_ERROR_MESSAGES.email.pattern,
      pattern: FORM_ERROR_MESSAGES.email.pattern,
    },
  },
  insured: {
    firstName: {
      pattern: FORM_ERROR_MESSAGES.name.invalid,
      minlength: FORM_ERROR_MESSAGES.name.first.minLength,
    },
    middleName: {
      pattern: FORM_ERROR_MESSAGES.name.invalid,
    },
    lastName: {
      pattern: FORM_ERROR_MESSAGES.name.invalid,
      minlength: FORM_ERROR_MESSAGES.name.last.minLength,
    },
    address: {
      line1: {
        required: FORM_ERROR_MESSAGES.insurance.required.address.line1,
        minlength: FORM_ERROR_MESSAGES.address.line1.minLength,
      },
      line2: {
        pattern: FORM_ERROR_MESSAGES.address.line2.invalid,
      },
      zipCode: {
        required: FORM_ERROR_MESSAGES.insurance.required.address.zipcode,
        pattern: FORM_ERROR_MESSAGES.address.zipCode.minLength,
      },
      city: {
        required: FORM_ERROR_MESSAGES.insurance.required.address.city,
        pattern: FORM_ERROR_MESSAGES.address.city.pattern,
        minlength: FORM_ERROR_MESSAGES.address.city.minLength,
      },
      state: {
        required: FORM_ERROR_MESSAGES.insurance.required.address.state,
      },
    },
    employer: {
      pattern: FORM_ERROR_MESSAGES.insuredEmployer.invalid,
      minlength: FORM_ERROR_MESSAGES.common.minLength,
    },
  },
  insurance: {
    coverage: {
      required: FORM_ERROR_MESSAGES.common.required,
    },
    otherInsuranceName: {
      required: FORM_ERROR_MESSAGES.insurance.required.insuranceName,
      minlength: FORM_ERROR_MESSAGES.common.minLength,
      pattern: FORM_ERROR_MESSAGES.insuranceName.invalid,
    },
    medicaidState: {
      required: FORM_ERROR_MESSAGES.insurance.required.medicaidState,
    },
    subscriberNumber: {
      required: FORM_ERROR_MESSAGES.insurance.required.subscriberNumber,
      minlength: FORM_ERROR_MESSAGES.common.minLength,
      pattern: FORM_ERROR_MESSAGES.common.alphaNumeric,
    },
    planName: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.common.minLength,
      pattern: FORM_ERROR_MESSAGES.common.alphaNumSpecial,
    },
    groupNumber: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.common.minLength,
    },
    address: {
      line1: {
        required: FORM_ERROR_MESSAGES.insurance.required.address.line1,
        minlength: FORM_ERROR_MESSAGES.address.line1.minLength,
      },
      line2: {
        pattern: FORM_ERROR_MESSAGES.address.line2.invalid,
      },
      zipCode: {
        required: FORM_ERROR_MESSAGES.insurance.required.address.zipcode,
        pattern: FORM_ERROR_MESSAGES.address.zipCode.minLength,
      },
      city: {
        required: FORM_ERROR_MESSAGES.insurance.required.address.city,
        pattern: FORM_ERROR_MESSAGES.address.city.pattern,
        minlength: FORM_ERROR_MESSAGES.address.city.minLength,
      },
      state: {
        required: FORM_ERROR_MESSAGES.insurance.required.address.state,
      },
      country: {
        minlength: FORM_ERROR_MESSAGES.insuranceCountry.minLength,
      },
    },
    phoneNumber: {
      pattern: FORM_ERROR_MESSAGES.phone.pattern,
    },
  },
  contactEmail: {
    required: FORM_ERROR_MESSAGES.insurance.required.contactEmail,
    pattern: FORM_ERROR_MESSAGES.email.pattern,
  },
  contactPhone: {
    pattern: FORM_ERROR_MESSAGES.phone.pattern,
  },
  additionalInvoices: {
    pattern: FORM_ERROR_MESSAGES.additionalInvoices.pattern,
    maxlength: FORM_ERROR_MESSAGES.additionalInvoices.maxLength,
  },
};

export const microbiomeStartFormValidationMessages = {
  patient: {
    dateOfBirth: {
      required: FORM_ERROR_MESSAGES.common.required,
      pattern: FORM_ERROR_MESSAGES.date.pattern,
      invalidDate: FORM_ERROR_MESSAGES.date.invalidDate,
      mask: FORM_ERROR_MESSAGES.date.mask,
      invalidYear: FORM_ERROR_MESSAGES.date.invalidYear,
    },
    emailAddress: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.email.pattern,
      pattern: FORM_ERROR_MESSAGES.email.pattern,
    },
    acctno: {
      required: FORM_ERROR_MESSAGES.common.required,
    },
    reqno: {
      required: FORM_ERROR_MESSAGES.common.required,
    },
  },
};

export const fastRegistrationStartFormValidationMessages = {
  patient: {
    dateOfBirth: {
      required: FORM_ERROR_MESSAGES.common.required,
      pattern: FORM_ERROR_MESSAGES.date.pattern,
      invalidDate: FORM_ERROR_MESSAGES.date.invalidDate,
      mask: FORM_ERROR_MESSAGES.date.mask,
      invalidYear: FORM_ERROR_MESSAGES.date.invalidYear,
    },
    zipCode: {
      required: FORM_ERROR_MESSAGES.common.required,
      minLength: FORM_ERROR_MESSAGES.address.zipCode.minLength,
      pattern: FORM_ERROR_MESSAGES.address.zipCode.pattern,
    },
  },
};

export const fastRegistrationCreateFormValidationMessages = {
  patient: {
    password: {
      required: FORM_ERROR_MESSAGES.common.required,
    },
    confirmPassword: {
      required: FORM_ERROR_MESSAGES.common.required,
      fieldsMatch: FORM_ERROR_MESSAGES.password.notEqualSimple,
    },
    phone: {
      required: FORM_ERROR_MESSAGES.common.required,
      pattern: FORM_ERROR_MESSAGES.phone.pattern,
      mask: '',
    },
  },
};

export const medicalRecordShareStartFormValidationMessages = {
  patient: {
    dateOfBirth: {
      required: FORM_ERROR_MESSAGES.common.required,
      pattern: FORM_ERROR_MESSAGES.date.pattern,
      invalidDate: FORM_ERROR_MESSAGES.date.invalidDate,
      mask: FORM_ERROR_MESSAGES.date.mask,
      invalidYear: FORM_ERROR_MESSAGES.date.invalidYear,
    },
    zipCode: {
      required: FORM_ERROR_MESSAGES.common.required,
      minLength: FORM_ERROR_MESSAGES.address.zipCode.minLength,
      pattern: FORM_ERROR_MESSAGES.address.zipCode.pattern,
    },
  },
};

export const medicalRecordShareSubmitFormValidationMessages = {
  patient: {
    firstName: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.name.first.minLength,
      maxlength: FORM_ERROR_MESSAGES.name.first.maxLength,
      pattern: FORM_ERROR_MESSAGES.name.invalid,
    },
    middleName: {
      maxlength: FORM_ERROR_MESSAGES.name.middle.maxLength,
      pattern: FORM_ERROR_MESSAGES.name.invalid,
    },
    lastName: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.name.last.minLength,
      maxlength: FORM_ERROR_MESSAGES.name.last.maxLength,
      pattern: FORM_ERROR_MESSAGES.name.invalid,
    },
    prevLastName: {
      maxlength: FORM_ERROR_MESSAGES.name.last.maxLength,
      pattern: FORM_ERROR_MESSAGES.name.invalid,
    },
    address: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.address.line1.minLength,
      maxlength: FORM_ERROR_MESSAGES.address.line1.maxLength,
    },
    city: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.address.city.minLength,
      maxlength: FORM_ERROR_MESSAGES.address.city.maxLength,
      pattern: FORM_ERROR_MESSAGES.address.city.pattern,
    },
    state: {
      required: FORM_ERROR_MESSAGES.common.required,
    },
    zipCode: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.address.zipCode.minLength,
      pattern: FORM_ERROR_MESSAGES.address.zipCode.pattern,
    },
    phone: {
      required: FORM_ERROR_MESSAGES.common.required,
      pattern: FORM_ERROR_MESSAGES.phone.pattern,
      mask: '',
    },
    sex: {
      required: FORM_ERROR_MESSAGES.common.required,
    },
    signature: {
      required: FORM_ERROR_MESSAGES.common.required,
      minlength: FORM_ERROR_MESSAGES.signature.minLength,
      maxlength: FORM_ERROR_MESSAGES.signature.maxLength,
      pattern: FORM_ERROR_MESSAGES.name.invalid,
    },
  },
};

export const RESPONSE_ERROR_TEXT = {
  epsPayment: {
    200: 'We are not able to process your payment at this time. Please try again later.',
    202: 'We are not able to process your payment at this time. Please try again later.',
    204: 'Your payment method has been declined. Please try another one.',
    205: 'We are not able to process your payment at this time. Please try again later.',
    400: `We're experiencing technical difficulties. We're unable to complete your request at this time. Please try again later.`,
  },
  standardErrors: {
    400: `We're experiencing technical difficulties. We're unable to complete your request at this time. Please try again later.`,
  },
};

export const PASSWORD_RESET_TEXT = {
  verifyCode: {
    invalidCode:
      '<strong>You have entered a one-time Verification Code that is no longer valid.</strong> Please click <strong>Resend Code</strong> or <strong>Try Another Method</strong>.',
    errorMessage: `We're not able to complete your request at this time. Please try again later.`,
  },
};

export const signInForm_Error_Messages = {
  signInFormError: {
    email: {
      required: 'Email is required',
      pattern: 'Email is invalid',
    },
    password: {
      required: 'Password is required',
      pattern: 'Password is invalid',
    },
  },
  incorrectLogin: 'The email address or password you entered is incorrect.',
  requiredFields: 'Enter your email address and password.',
  techDifficulties: RESPONSE_ERROR_TEXT.standardErrors[400],
};

export const registrationFormValidationMessages = {
  firstName: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.name.invalid,
    minlength: FORM_ERROR_MESSAGES.name.first.minLength,
  },
  middleName: {
    pattern: FORM_ERROR_MESSAGES.name.middle.invalid,
  },
  lastName: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.name.invalid,
    minlength: FORM_ERROR_MESSAGES.name.last.minLength,
  },
  gender: {
    invalidRadio: FORM_ERROR_MESSAGES.common.required,
  },
  dateOfBirth: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.date.pattern,
    invalidDate: FORM_ERROR_MESSAGES.date.invalidDateNoMinors,
    invalidYear: FORM_ERROR_MESSAGES.date.invalidYear,
    mask: FORM_ERROR_MESSAGES.date.mask,
  },
  confirmDateOfBirth: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.date.pattern,
    invalidDate: FORM_ERROR_MESSAGES.date.invalidDateNoMinors,
    invalidYear: FORM_ERROR_MESSAGES.date.invalidYear,
    mask: FORM_ERROR_MESSAGES.date.mask,
    fieldsMatch: FORM_ERROR_MESSAGES.date.notEquivalent,
  },
  phoneNumber: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.phone.pattern,
    mask: '',
  },
  phoneType: {
    required: FORM_ERROR_MESSAGES.common.required,
  },
  emailAddress: {
    required: FORM_ERROR_MESSAGES.common.required,
    minlength: FORM_ERROR_MESSAGES.email.pattern,
    pattern: FORM_ERROR_MESSAGES.email.pattern,
  },
  confirmEmailAddress: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.email.pattern,
    fieldsMatch: FORM_ERROR_MESSAGES.email.notEquivalent,
  },
  password: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.password.pattern,
  },
  confirmPassword: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.password.pattern,
    fieldsMatch: FORM_ERROR_MESSAGES.password.notEqualSimple,
  },
  address: {
    required: FORM_ERROR_MESSAGES.common.required,
    maxlength: FORM_ERROR_MESSAGES.address.line1.maxLength50,
  },
  city: {
    required: FORM_ERROR_MESSAGES.common.required,
    minlength: FORM_ERROR_MESSAGES.address.city.minLength,
    maxlength: FORM_ERROR_MESSAGES.address.city.maxLength,
    pattern: FORM_ERROR_MESSAGES.address.city.pattern,
  },
  state: {
    required: FORM_ERROR_MESSAGES.common.required,
  },
  zipCode: {
    required: FORM_ERROR_MESSAGES.common.required,
    minlength: FORM_ERROR_MESSAGES.address.zipCode.minLength,
    pattern: FORM_ERROR_MESSAGES.address.zipCode.pattern,
  },
};

export const profileUpdatePasswordValidationMessages = {
  oldPassword: {
    required: FORM_ERROR_MESSAGES.password.required,
    incorrect: 'Your old password is incorrect. Please try again.',
  },
  newPassword: {
    required: FORM_ERROR_MESSAGES.password.required,
    pattern: FORM_ERROR_MESSAGES.password.pattern,
    cannotEqual:
      'Your new password cannot be the same as your current password. Please try again.',
    failedValidation:
      'Your new password failed one or more validation checks. Please try again.',
  },
  confirmPassword: {
    required: FORM_ERROR_MESSAGES.password.required,
    pattern: FORM_ERROR_MESSAGES.password.pattern,
    fieldsMatch: FORM_ERROR_MESSAGES.password.notEquivalent,
  },
};

export const profileUpdateDemographicsFormValidationMessages = {
  firstName: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.name.invalid,
    minlength: FORM_ERROR_MESSAGES.name.first.minLength,
  },
  middleName: {
    pattern: FORM_ERROR_MESSAGES.name.middle.invalid,
  },
  lastName: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.name.invalid,
    minlength: FORM_ERROR_MESSAGES.name.last.minLength,
  },
  gender: {
    required: FORM_ERROR_MESSAGES.common.required,
  },
};

export const profileAddressFormValidationMessages = {
  line1: {
    minlength: 'Address line 1 must contain between 3 and 50 characters',
    required: FORM_ERROR_MESSAGES.common.required,
  },
  line2: {
    invalid: FORM_ERROR_MESSAGES.address.line2,
    required: FORM_ERROR_MESSAGES.common.required,
  },
  city: {
    minlength: 'Minimum value must be at least 3 characters',
    pattern: FORM_ERROR_MESSAGES.address.city.pattern,
    required: FORM_ERROR_MESSAGES.common.required,
  },
  state: {
    required: FORM_ERROR_MESSAGES.common.required,
  },
  zipCode: {
    pattern: FORM_ERROR_MESSAGES.address.zipCode.pattern,
    required: FORM_ERROR_MESSAGES.common.required,
  },
};

export const addDependentFormValidationMessages = {
  relationshipType: {
    required: FORM_ERROR_MESSAGES.common.required,
  },
  firstName: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.name.invalid,
    minlength: FORM_ERROR_MESSAGES.name.first.minLength,
  },
  middleName: {
    pattern: FORM_ERROR_MESSAGES.name.middle.invalid,
  },
  lastName: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.name.invalid,
    minlength: FORM_ERROR_MESSAGES.name.last.minLength,
  },
  gender: {
    invalidRadio: FORM_ERROR_MESSAGES.common.required,
  },
  dateOfBirth: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.date.pattern,
    invalidDate: FORM_ERROR_MESSAGES.date.invalidDate,
    invalidDateNotMinor: FORM_ERROR_MESSAGES.date.invalidDateNotMinor,
    invalidYear: FORM_ERROR_MESSAGES.date.invalidYear,
    mask: FORM_ERROR_MESSAGES.date.mask,
  },
  confirmDateOfBirth: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.date.pattern,
    invalidDate: FORM_ERROR_MESSAGES.date.invalidDate,
    invalidYear: FORM_ERROR_MESSAGES.date.invalidYear,
    mask: FORM_ERROR_MESSAGES.date.mask,
    fieldsMatch: FORM_ERROR_MESSAGES.date.notEquivalent,
  },
  phoneNumber: {
    required: FORM_ERROR_MESSAGES.common.required,
    pattern: FORM_ERROR_MESSAGES.phone.pattern,
    mask: '',
  },
  phoneType: {
    required: FORM_ERROR_MESSAGES.common.required,
  },
  emailAddress: {
    required: FORM_ERROR_MESSAGES.common.required,
    minlength: FORM_ERROR_MESSAGES.email.pattern,
    pattern: FORM_ERROR_MESSAGES.email.pattern,
  },
  address: {
    required: FORM_ERROR_MESSAGES.common.required,
    maxlength: FORM_ERROR_MESSAGES.address.line1.maxLength50,
  },
  city: {
    required: FORM_ERROR_MESSAGES.common.required,
    minlength: FORM_ERROR_MESSAGES.address.city.minLength,
    maxlength: FORM_ERROR_MESSAGES.address.city.maxLength,
    pattern: FORM_ERROR_MESSAGES.address.city.pattern,
  },
  state: {
    required: FORM_ERROR_MESSAGES.common.required,
  },
  zipCode: {
    required: FORM_ERROR_MESSAGES.common.required,
    minlength: FORM_ERROR_MESSAGES.address.zipCode.minLength,
    pattern: FORM_ERROR_MESSAGES.address.zipCode.pattern,
  },
  errorMessages: {
    serverError: `We're not able to complete your request at this time. Please try again later.`,
    invalidForm:
      '<strong>There are items that need your attention.</strong> Fields that need attention are highlighted in red below.',
    invalidInfo: `You have registered a dependent with information that is not valid. Please confirm the information and try again.`,
    addressVerificationError:
      '<strong>We are having trouble verifying your address.</strong> <br>Please review it, make any changes necessary, and acknowledge that your address is correct before submitting your changes.',
    alreadyRegisteredDependentError: `
    <div>
      <h3><strong>Duplicate Account Detected</strong></h3>
      <p>An existing account for Jill Smith has been detected based on the demographic information provided.</p>
      <p>Jill Smith may be a dependent on another account or may have their own account. A person can only be associated with one account. To add Jill to your account:</p>
      <ul>
        <li>If Jill is a dependent on someone else's account, that account holder must sign in to Labcorp Patient and remove Jill as a dependent.</li>
        <li>If Jill has a Labcorp Patient account, Jill must sign in and delete their account by visiting the Profile page and clicking <strong>Delete Account</strong>.</li>
      </ul>
      <p>If you feel you are receiving this message in error, <a href="https://www.labcorp.com/contact-us/labcorp-patient-portal" target="_blank">contact us</a>.</p>
    </div>
  `,
  },
};
