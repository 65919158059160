import { IInsuranceDetail } from './IInsuranceDetail.interface';

export interface IInsuranceUpdateDetail {
  resubmit: boolean;
  checkEligibility: boolean;
  secondaryInsuranceOnly?: boolean;
  insuranceUpdate: IInsuranceDetail;
  numberType: InsuranceUpdateNumberType;
  dateOfBirth: string | null;
  patientId: string | null;
}

export enum InsuranceUpdateNumberType {
  INVOICE = 'INVOICE',
  SPECIMEN = 'SPECIMEN',
}
